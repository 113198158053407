import React, { useState, useEffect } from "react"
import { Container } from "@material-ui/core"
import AdminPanel from "../AdminPanel"
import {
  Grid,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core"
import Title from "../Title"
import axios from "../../axiosConfig"
import { errorNotifier } from "../Notifier"
import { discountPrice } from "../utils"

const useStyles = makeStyles(() => ({
  rowHeader: {
    fontWeight: "bold",
  },
}))

const LocalViewer = ({ setLoading }) => {
  const classes = useStyles()
  const [localList, setLocalList] = useState([])

  useEffect(() => {
    setLoading(true)
    axios
      .get("codetypes/localview")
      .then((res) => {
        setLocalList(res.data)
        setLoading(false)
      })
      .catch((error) => {
        errorNotifier(
          `Somethign went wrong, cannot get local list ...(${error.message})`
        )
        setLoading(false)
      })
    // eslint-disable-next-line
  }, [])

  return (
    <AdminPanel>
      <Container maxWidth={false}>
        <Grid container spacing={3}>
          <>
            <Grid item xs={12} container justify="space-evenly">
              <Grid item xs={12}>
                <Title>Local Packages Information</Title>
              </Grid>
            </Grid>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell className={classes.rowHeader}>Name</TableCell>
                  <TableCell className={classes.rowHeader}>Period</TableCell>
                  <TableCell className={classes.rowHeader}>Price</TableCell>
                  <TableCell className={classes.rowHeader}>Total</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {localList.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell>{row.local}</TableCell>
                    <TableCell>{row.period}</TableCell>
                    <TableCell>
                      {discountPrice(row.price, row.discount)}
                    </TableCell>
                    <TableCell>{row.total}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </>
        </Grid>
      </Container>
    </AdminPanel>
  )
}

export default LocalViewer
