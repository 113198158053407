import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import { connect } from "react-redux"
import styles from "./AdminPanelStyle"
import AppBar from "./AppBar"
import Drawer from "./Drawer"
import { setDrawer } from "../actions/drawerActions"

const useStyles = makeStyles(styles)

function AdminPanel({ children, drawer, setDrawer, ...rest }) {
  const classes = useStyles()
  const { open } = drawer

  const handleDrawerOpen = () => {
    setDrawer({
      open: true,
    })
  }

  const handleDrawerClose = () => {
    setDrawer({
      open: false,
    })
  }

  return (
    <div className={classes.root}>
      <AppBar open={open} handleDrawerOpen={handleDrawerOpen}></AppBar>
      <Drawer
        open={open}
        handleDrawerClose={handleDrawerClose}
        handleDrawerOpen={handleDrawerOpen}
      ></Drawer>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <div {...rest}>{children}</div>
      </main>
    </div>
  )
}

const mapStateToProps = (state) => {
  return { drawer: state.drawer }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setDrawer: (data) => {
      dispatch(setDrawer(data))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminPanel)
