import React, { useState, useEffect } from "react"

import { Container } from "@material-ui/core"
import AdminPanel from "../AdminPanel"
import clsx from "clsx"
import PropTypes from "prop-types"
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  makeStyles,
  Typography,
} from "@material-ui/core"
import { errorNotifier, successNotifier } from "../Notifier"
import axios from "../../axiosConfig"
import { withRouter } from "react-router-dom"
import { checkPassStrength } from "../utils"

const useStyles = makeStyles(() => ({
  root: {},
}))

const intialValues = {
  usergroupID: 2,
  name: "",
  userName: "",
  address: "",
  phone: "",
  email: "",
  password: "",
  confirmPassword: "",
  discount: false,
  block: false,
}

const AddUser = ({ className, history, setLoading }) => {
  const classes = useStyles()
  const [values, setValues] = useState(intialValues)
  const [users, setUsers] = useState()
  const [term, setTerm] = useState()

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    })
  }

  const saveUser = () => {
    if (values.name === "") {
      errorNotifier("Please fill in name field")
      return
    }
    if (values.userName === "") {
      errorNotifier("Please fill in userName field")
      return
    }
    if (values.email === "") {
      errorNotifier("Please fill in email field")
      return
    }
    if (values.password === "") {
      errorNotifier("Please fill in password and confirm password fields")
      return
    }

    if (values.password !== values.confirmPassword) {
      errorNotifier("Password and confirm password dosn't match.")
      return
    }

    setLoading(true)

    let data = {
      ...values,
      discount: values.discount === "true" ? true : false,
      block: values.block === "true" ? true : false,
    }
    delete data["confirmPassword"]

    axios
      .post("users", data)
      .then((res) => {
        successNotifier("User added successfully")
        setValues(intialValues)
        setLoading(false)
        history.push({
          pathname: `/users`,
          state: {
            users: users,
            term: term,
          },
        })
      })
      .catch((error) => {
        errorNotifier(
          `Something went wrong while adding new user...(${error.message})`
        )
        setLoading(false)
      })
  }

  useEffect(() => {
    let users = history.location.state?.users
    let term = history.location.state?.term

    setUsers(users)
    setTerm(term)

    // eslint-disable-next-line
  }, [])

  return (
    <AdminPanel>
      <Container maxWidth={false}>
        <div className={clsx(classes.root, className)}>
          <Card>
            <CardHeader
              subheader="Please fill in all required fields"
              title="User Profile"
            />
            <Divider />
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    helperText="Please specify the name"
                    label="Name"
                    name="name"
                    onChange={handleChange}
                    required
                    value={values.name}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="User Name"
                    name="userName"
                    onChange={handleChange}
                    required
                    value={values.userName}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Password"
                    name="password"
                    onChange={handleChange}
                    required
                    value={values.password}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Confirm Password"
                    name="confirmPassword"
                    onChange={handleChange}
                    required
                    value={values.confirmPassword}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Email Address"
                    name="email"
                    onChange={handleChange}
                    required
                    value={values.email}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Phone Number"
                    name="phone"
                    onChange={handleChange}
                    type="number"
                    value={values.phone}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Address"
                    name="address"
                    onChange={handleChange}
                    value={values.address}
                    variant="outlined"
                  />
                </Grid>

                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Discount"
                    name="discount"
                    onChange={handleChange}
                    required
                    select
                    SelectProps={{ native: true }}
                    value={values.discount}
                    variant="outlined"
                  >
                    {[
                      { value: true, name: "Yes" },
                      { value: false, name: "No" },
                    ].map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.name}
                      </option>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Block"
                    name="block"
                    onChange={handleChange}
                    required
                    select
                    SelectProps={{ native: true }}
                    value={values.block}
                    variant="outlined"
                  >
                    {[
                      { value: true, name: "Yes" },
                      { value: false, name: "No" },
                    ].map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.name}
                      </option>
                    ))}
                  </TextField>
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
            <div
              style={{
                width: "96%",
                marginLeft: "auto",
                marginRight: "auto",
                marginTop: "20px",
              }}
            >
              {values.password && (
                <Typography
                  style={{
                    color:
                      checkPassStrength(values.password) === "weak"
                        ? "red"
                        : checkPassStrength(values.password) === "good"
                        ? "green"
                        : "darkgreen",
                  }}
                >
                  {"Your password strength is : "}
                  {checkPassStrength(values.password)}
                </Typography>
              )}
              <Typography
                variant="body2"
                style={{ color: "grey", marginTop: "20px" }}
              >
                tips: for stronger password please use digits, lower, and upper
                case alphabets and non-word
              </Typography>
            </div>
            <Box
              display="flex"
              justifyContent="flex-end"
              p={2}
              onClick={saveUser}
            >
              <Button color="primary" variant="contained">
                Save User
              </Button>
            </Box>
          </Card>
        </div>
      </Container>
    </AdminPanel>
  )
}

AddUser.propTypes = {
  className: PropTypes.string,
  setLoading: PropTypes.func,
}

export default withRouter(AddUser)
