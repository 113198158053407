import React, { useState, useEffect } from "react"
import AdminPanel from "../AdminPanel"
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  makeStyles,
} from "@material-ui/core"
import axios from "../../axiosConfig"
import { errorNotifier, successNotifier } from "../Notifier"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import { findDuplicate, toFixed2 } from "./../utils"

const useStyles = makeStyles(() => ({
  root: {},
}))

const intialValues = {
  detailID: "",
  localID: "",
  periodID: "",
  price: "",
  amount: "",
  cards: {},
}

const AddLocalCard = ({ setLoading, admin, history }) => {
  const classes = useStyles()
  const [localTypes, setLocalTypes] = useState([])
  const [periodTypes, setPeriodTypes] = useState([])

  const [values, setValues] = useState(intialValues)

  const handleChange = (event) => {
    if (event.target.name === "amount") {
      setValues({
        ...values,
        cards: {},
        [event.target.name]: event.target.value,
      })
    } else {
      setValues({
        ...values,
        [event.target.name]: event.target.value,
      })
    }
  }

  const getOldPrice = (intialLocalID, intialPeriodID) => {
    setLoading(true)
    axios
      .post("Codetypes/LocalDetail", {
        localID: values.localID ? Number(values.localID) : intialLocalID,
        periodID: values.periodID ? Number(values.periodID) : intialPeriodID,
      })
      .then((res) => {
        setValues({
          ...values,
          cards: {},
          localID: values.localID || intialLocalID,
          periodID: values.periodID || intialPeriodID,
          detailID: res.data.detailID,
          price: res.data.price,
        })
        setLoading(false)
      })
      .catch((error) => {
        errorNotifier(
          `Somethign went wrong, cannot set local price ...(${error.message})`
        )

        setLoading(false)
      })
  }

  const addCards = () => {
    let userID = admin.userID

    if (!userID) {
      errorNotifier("No logged admin found please try again...")
      return
    }

    if (Object.keys(values.cards).length === 0) {
      errorNotifier("Please enter at least one card number")
      return
    }

    if (values.detailID === "") {
      errorNotifier("Please select correct package and period")
      return
    }

    if (values.price === "") {
      errorNotifier("Card price is missing, please set card price first")
      return
    }

    let errorLength = false
    Object.keys(values.cards).forEach((key, index) => {
      if (
        !(
          values.cards[key].card.trim().length >= 8 &&
          values.cards[key].card.trim().length <= 50
        )
      ) {
        errorNotifier(
          `Card number length must be between 8 to 50 characters  ${key} is ${
            values.cards[key].card.trim().length
          }  `
        )
        errorLength = true
      }
    })

    if (errorLength) {
      return
    }

    let finalCards = []

    Object.keys(values.cards).forEach((key, index) => {
      finalCards.push({
        detailID: values.detailID,
        code: values.cards[key].card.trim(),
        quantity: 1,
        price: values.price,
      })
    })

    let codes = finalCards.map((card) => card.code)
    let duplicatedCodes = findDuplicate(codes)
    if (duplicatedCodes.length > 0) {
      errorNotifier(
        `card: ${duplicatedCodes.join(
          "  card: "
        )} are duplicate, card number must be unique`
      )
      return
    }

    let data = {
      userID: userID,
      date: new Date().toJSON(),
      Transaction: {
        paid: finalCards.length * values.price,
      },
      Codes: finalCards,
    }

    setLoading(true)
    axios
      .post("records", data)
      .then((res) => {
        successNotifier("Local package cards added successfully")
        setLoading(false)
        setValues(intialValues)
        history.goBack()
      })
      .catch((error) => {
        errorNotifier(
          `Somethign went wrong, cannot add local cards ...(${error.message})`
        )
        setLoading(false)
      })
  }

  const fetchLocaPeriodTypes = () => {
    setLoading(true)
    Promise.all([axios.get(`codetypes/Local`), axios.get(`codetypes/Period`)])
      .then(([localRes, periodRes]) => {
        setLocalTypes(localRes.data)
        setPeriodTypes(periodRes.data)
        setValues({
          ...values,
          localID: localRes.data[0]["localID"],
          periodID: periodRes.data[0]["periodID"],
          price: "",
          detailID: "",
        })
        getOldPrice(localRes.data[0]["localID"], periodRes.data[0]["periodID"])
        setLoading(false)
      })
      .catch((error) => {
        errorNotifier(
          `Somethign went wrong, please try again ...(${error.message})`
        )
        setLoading(false)
      })
  }

  useEffect(
    () => {
      if (values.localID && values.periodID) {
        getOldPrice()
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [values.localID, values.periodID]
  )

  useEffect(() => {
    fetchLocaPeriodTypes()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleCardChange = (event) => {
    let cards = { ...values.cards }
    cards[event.target.name] = {
      card: event.target.value,
    }

    setValues({
      ...values,
      cards,
    })
  }

  let numberOfGeneratedFields = 0
  if (values.amount !== "") {
    numberOfGeneratedFields = Math.round(Number(values.amount))
  }

  let generatedCards = []
  if (numberOfGeneratedFields > 0) {
    for (let i = 1; i <= numberOfGeneratedFields; i++) {
      generatedCards.push({
        index: i,
      })
    }
  }

  return (
    <AdminPanel>
      <div className={classes.root}>
        <Card>
          <CardHeader
            title="Add Local Card"
            subheader="Adding cards for local packages"
          />
          <Divider />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Select Local Type"
                  name="localID"
                  onChange={handleChange}
                  required
                  select
                  SelectProps={{ native: true }}
                  value={values.localID}
                  variant="outlined"
                >
                  {localTypes.map((option) => (
                    <option key={option.localID} value={option.localID}>
                      {option.name}
                    </option>
                  ))}
                </TextField>
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Select Period Type"
                  name="periodID"
                  onChange={handleChange}
                  required
                  select
                  SelectProps={{ native: true }}
                  value={values.periodID}
                  variant="outlined"
                >
                  {periodTypes.map((option) => (
                    <option key={option.periodID} value={option.periodID}>
                      {option.name}
                    </option>
                  ))}
                </TextField>
              </Grid>

              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="price"
                  name="price"
                  disabled
                  value={`${toFixed2(values.price)} $`}
                  variant="outlined"
                />
              </Grid>

              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  helperText="Please specify the card numbers"
                  label="amount"
                  name="amount"
                  onChange={handleChange}
                  required
                  type="number"
                  value={values.amount}
                  variant="outlined"
                  inputProps={{ min: "0", step: "1" }}
                />
              </Grid>
              {generatedCards.map((item, index) => (
                <Grid item md={6} xs={12} key={index}>
                  <TextField
                    fullWidth
                    label={`Card Number ${item.index}`}
                    name={`card_${item.index}`}
                    onChange={handleCardChange}
                    required
                    value={
                      Object.keys(values.cards).length > 0 &&
                      values.cards[`card_${item.index}`] !== undefined
                        ? values.cards[`card_${item.index}`]["card"]
                        : ""
                    }
                    variant="outlined"
                  />
                </Grid>
              ))}
            </Grid>
          </CardContent>
          <Divider />
          <Box display="flex" justifyContent="flex-end" p={2}>
            <Button color="primary" variant="contained" onClick={addCards}>
              Add Cards
            </Button>
          </Box>
        </Card>
      </div>
    </AdminPanel>
  )
}

const mapStateToProps = (state) => {
  return { admin: state.admin }
}

export default withRouter(connect(mapStateToProps)(AddLocalCard))
