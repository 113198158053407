import React from "react"
import { Route, Redirect } from "react-router-dom"
import { connect } from "react-redux"
import { logoutAdmin } from "../actions/adminActions"
import { isAuthenticated } from "./utils"

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
function SigninRoute({ children, admin, logout, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuthenticated(admin, logout) ? (
          <Redirect
            to={{
              pathname: "/",
              state: { from: location },
            }}
          />
        ) : (
          children
        )
      }
    />
  )
}

const mapStateToProps = (state) => {
  return { admin: state.admin }
}

const mapDispatchToProps = (dispatch) => {
  return {
    logout: (admin) => {
      dispatch(logoutAdmin())
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SigninRoute)
