import React, { useState, Suspense } from "react"
import ThemeProvider from "./ThemeProvider" // use theme provider only for English
import { connect } from "react-redux"
import { loginAdmin, logoutAdmin } from "../actions/adminActions"
import {
  BrowserRouter as Router,
  Switch,
  Route,
  // Link
} from "react-router-dom"
import { ToastContainer } from "react-toastify"
import ScrollToTop from "./ScrollToTop"
import Backdrop from "@material-ui/core/Backdrop"
import CircularProgress from "@material-ui/core/CircularProgress"
import { makeStyles } from "@material-ui/core/styles"
import Box from "@material-ui/core/Box"

import PrivateRoute from "./PrivateRoute"
import SigninRoute from "./SigninRoute"
import orange from "@material-ui/core/colors/orange"

import LocalPackages from "./local/LocalPackages"
import LocalSetPrice from "./local/LocalSetPrice"
import LocalViewer from "./local/LocalViewer"
import AddLocalCard from "./local/AddLocalCard"

import GlobalPackages from "./global/GlobalPackages"
import GlobalSetPrice from "./global/GlobalSetPrice"
import GlobalViewer from "./global/GlobalViewer"
import AddGlobalCard from "./global/AddGlobalCard"

import Users from "./users/Users"
import AddUser from "./users/AddUser"
import EditUser from "./users/EditUser"
import TransferUser from "./users/TransferUser"
import UserLogs from "./users/Logs"

import CodeHistory from "./codeHistory/index"
import SingleGlobalCard from "./codeHistory/card"

import Profits from "./profit/index"

import NotFoundContent from "./NotFoundContent"

import Dashboard from "./dashboard/index"
import Signin from "./Signin/Signin"

import Account from "./account/index"
import UsersReport from "./users_report/index"
import UsersReportLogs from "./users_report/logs"

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: orange[500],
  },
}))

function App() {
  const classes = useStyles()
  const [loading, setLoading] = useState(false)

  return (
    <div>
      <Suspense
        fallback={
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            style={{ height: "100vh" }}
          >
            <CircularProgress color="inherit" />
          </Box>
        }
      >
        <Backdrop className={classes.backdrop} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>

        <ThemeProvider>
          <Router>
            <ScrollToTop>
              <Switch>
                <SigninRoute exact path="/Signin">
                  <Signin setLoading={setLoading}></Signin>
                </SigninRoute>

                <PrivateRoute exact path="/">
                  <Dashboard />
                </PrivateRoute>

                <PrivateRoute exact path="/local">
                  <LocalPackages setLoading={setLoading} />
                </PrivateRoute>

                <PrivateRoute exact path="/local/setprice">
                  <LocalSetPrice setLoading={setLoading} />
                </PrivateRoute>

                <PrivateRoute exact path="/local/viewer">
                  <LocalViewer setLoading={setLoading} />
                </PrivateRoute>

                <PrivateRoute exact path="/local/add">
                  <AddLocalCard setLoading={setLoading} />
                </PrivateRoute>

                <PrivateRoute exact path="/global">
                  <GlobalPackages setLoading={setLoading} />
                </PrivateRoute>

                <PrivateRoute exact path="/global/setprice">
                  <GlobalSetPrice setLoading={setLoading} />
                </PrivateRoute>

                <PrivateRoute exact path="/global/viewer">
                  <GlobalViewer setLoading={setLoading} />
                </PrivateRoute>

                <PrivateRoute exact path="/global/add">
                  <AddGlobalCard setLoading={setLoading} />
                </PrivateRoute>

                <PrivateRoute exact path="/users">
                  <Users setLoading={setLoading} />
                </PrivateRoute>
                <PrivateRoute exact path="/users/add">
                  <AddUser setLoading={setLoading} />
                </PrivateRoute>
                <PrivateRoute exact path="/users/edit">
                  <EditUser setLoading={setLoading} />
                </PrivateRoute>

                <PrivateRoute exact path="/users/transfer">
                  <TransferUser setLoading={setLoading} />
                </PrivateRoute>
                <PrivateRoute exact path="/users/logs">
                  <UserLogs setLoading={setLoading} />
                </PrivateRoute>

                <PrivateRoute exact path="/code_history">
                  <CodeHistory setLoading={setLoading} />
                </PrivateRoute>
                <PrivateRoute exact path="/code_history/card">
                  <SingleGlobalCard setLoading={setLoading} />
                </PrivateRoute>

                <PrivateRoute exact path="/profits">
                  <Profits setLoading={setLoading} />
                </PrivateRoute>

                <PrivateRoute exact path="/account">
                  <Account setLoading={setLoading} />
                </PrivateRoute>

                <PrivateRoute exact path="/users_report">
                  <UsersReport setLoading={setLoading} />
                </PrivateRoute>
                <PrivateRoute exact path="/users_report/logs">
                  <UsersReportLogs setLoading={setLoading} />
                </PrivateRoute>

                <Route path="*" component={NotFoundContent}></Route>
              </Switch>
            </ScrollToTop>
          </Router>
          <ToastContainer />
        </ThemeProvider>
      </Suspense>
    </div>
  )
}

const mapStateToProps = (state) => {
  return { admin: state.admin }
}

const mapDispatchToProps = (dispatch) => {
  return {
    login: (admin) => {
      dispatch(loginAdmin(admin))
    },
    logout: (admin) => {
      dispatch(logoutAdmin())
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App)
