import React, { useState, useEffect } from "react"

import { Container } from "@material-ui/core"
import AdminPanel from "../AdminPanel"
import clsx from "clsx"
import PropTypes from "prop-types"
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  makeStyles,
} from "@material-ui/core"
import { errorNotifier, successNotifier } from "../Notifier"
import axios from "../../axiosConfig"
import { withRouter } from "react-router-dom"
import CancelOutlined from "@material-ui/icons/CancelOutlined"
import SendOutlined from "@material-ui/icons/SendOutlined"

const useStyles = makeStyles(() => ({
  root: {},
}))

const intialValues = {
  userID: "",
  date: new Date().toJSON(),
  amount: 0,
  transaction: {
    paid: "",
    get: "",
  },
}

const TransferUser = ({ className, history, setLoading }) => {
  const classes = useStyles()
  const [values, setValues] = useState(intialValues)
  const [user, setUser] = useState({})
  const [term, setTerm] = useState("")
  const [balance, setBalance] = useState(0)

  const handleChange = (event) => {
    setValues({
      ...values,
      amount: event.target.value,
    })
  }

  const transfer = (method) => {
    if (values.userID === "") {
      errorNotifier("User is missing please try again")
      return
    }
    if (values.amount === "") {
      errorNotifier("Please fill in amount field")
      return
    }

    if (method === "paid" && Number(values.amount) < 1) {
      errorNotifier("Amount filed value must be greater than 0 ")
      return
    }

    if (
      method === "get" &&
      (Number(values.amount) < 1 || Number(values.amount) > Number(balance))
    ) {
      errorNotifier(
        `Amount filed value must be greater than 0 and less than or equal too  ${balance} $`
      )
      return
    }

    setLoading(true)

    let data = {
      userID: values.userID,
      date: values.date,
      transaction: {},
    }
    if (method === "paid") {
      data.transaction.paid = Number(values.amount)
    }
    if (method === "get") {
      data.transaction.get = Number(values.amount)
    }

    axios
      .post("transfers", data)
      .then((res) => {
        if (method === "paid") {
          successNotifier(
            `User balance charged by ${values.amount} $ successfully`
          )
        } else {
          successNotifier(
            `Withdrawed ${values.amount} $ from User balance successfully`
          )
        }

        setValues(intialValues)
        setLoading(false)
        history.push({
          pathname: `/users`,
          state: {
            term: term,
          },
        })
      })
      .catch((error) => {
        errorNotifier(
          `Something went wrong while updating user...(${error.message})`
        )
        setLoading(false)
      })
  }

  const getBalance = (userID) => {
    axios
      .post(`users/balance`, {
        userID: userID,
      })
      .then((res) => {
        if (parseFloat(res.data).toFixed(2) >= 0) {
          setBalance(parseFloat(res.data).toFixed(2))
        }
      })
  }

  useEffect(() => {
    let user = history.location.state?.user
    let term = history.location.state?.term

    if (typeof user["userID"] === "number") {
      getBalance(user["userID"])
    }

    setUser(user)
    setTerm(term)
    setValues({
      ...values,
      userID: user["userID"],
    })
    // eslint-disable-next-line
  }, [])

  return (
    <AdminPanel>
      <Container maxWidth={false}>
        <div className={clsx(classes.root, className)}>
          <Card>
            <CardHeader
              subheader="Please fill amount field"
              title="Transfer User"
            />
            <Divider />
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    disabled
                    label="Name"
                    name="name"
                    value={user.name}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    disabled
                    label="User Name"
                    name="userName"
                    value={user.userName}
                    variant="outlined"
                  />
                </Grid>

                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    disabled
                    label="Email Address"
                    name="email"
                    value={user.email}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    disabled
                    label="Phone Number"
                    name="phone"
                    type="number"
                    value={user.phone}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    disabled
                    label="Address"
                    name="address"
                    value={user.address}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    disabled
                    label="Balance"
                    name="balance"
                    value={` ${balance} $`}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    required
                    label="Amount"
                    name="amount"
                    type="number"
                    onChange={handleChange}
                    value={values.amount}
                    variant="outlined"
                    inputProps={{ min: "0" }}
                  />
                </Grid>
              </Grid>
            </CardContent>
            <Divider />

            <Box display="flex" justifyContent="flex-end" p={2}>
              <Button
                style={{ marginRight: "10px" }}
                color="secondary"
                variant="contained"
                startIcon={<CancelOutlined />}
                disabled={balance <= 0}
                onClick={() => {
                  transfer("get")
                }}
              >
                Return Money
              </Button>
              <Button
                color="primary"
                variant="contained"
                startIcon={<SendOutlined />}
                onClick={() => {
                  transfer("paid")
                }}
              >
                Send Money
              </Button>
            </Box>
          </Card>
        </div>
      </Container>
    </AdminPanel>
  )
}

TransferUser.propTypes = {
  className: PropTypes.string,
  setLoading: PropTypes.func,
}

export default withRouter(TransferUser)
