import React, { useState, useEffect } from "react"
import { Container } from "@material-ui/core"
import AdminPanel from "../AdminPanel"
import {
  Grid,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core"
import Title from "../Title"
import axios from "../../axiosConfig"
import { errorNotifier } from "../Notifier"
import { toFixed2 } from "../utils"
import HistoryOutlined from "@material-ui/icons/HistoryOutlined"
import { withRouter } from "react-router-dom"

const useStyles = makeStyles(() => ({
  rowHeader: {
    fontWeight: "bold",
  },
}))

const UsersReport = ({ setLoading, history }) => {
  const classes = useStyles()
  const [usersList, setUsersList] = useState([])

  useEffect(() => {
    setLoading(true)
    axios
      .get("users/userlist")
      .then((res) => {
        setUsersList(res.data)
        setLoading(false)
      })
      .catch((error) => {
        errorNotifier(
          `Somethign went wrong, cannot get global list ...(${error.message})`
        )
        setLoading(false)
      })
    // eslint-disable-next-line
  }, [])

  return (
    <AdminPanel>
      <Container maxWidth={false}>
        <Grid container spacing={3}>
          <>
            <Grid item xs={12} container justify="space-evenly">
              <Grid item xs={12}>
                <Title>Users List</Title>
              </Grid>
            </Grid>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell className={classes.rowHeader}>User Name</TableCell>
                  <TableCell className={classes.rowHeader}>Name</TableCell>
                  <TableCell className={classes.rowHeader}>Phone</TableCell>
                  <TableCell className={classes.rowHeader}>Discount</TableCell>
                  <TableCell className={classes.rowHeader}>Balance</TableCell>
                  <TableCell className={classes.rowHeader} align="center">
                    History
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {usersList.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell>{row.userName}</TableCell>
                    <TableCell>{row.name}</TableCell>
                    <TableCell>{row.phone}</TableCell>
                    <TableCell>{row.discount ? "Yes" : "No"}</TableCell>
                    <TableCell>{`${toFixed2(row.balance)} $`}</TableCell>
                    <TableCell
                      align="center"
                      onClick={() => {
                        history.push({
                          pathname: `/users_report/logs`,
                          state: { userID: row.userID, userName: row.userName },
                        })
                      }}
                    >
                      <HistoryOutlined
                        className={classes.icon}
                      ></HistoryOutlined>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </>
        </Grid>
      </Container>
    </AdminPanel>
  )
}

export default withRouter(UsersReport)
