import React, { useState, useEffect } from "react"
import AdminPanel from "../AdminPanel"
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  makeStyles,
} from "@material-ui/core"
import { withRouter } from "react-router-dom"
import { errorNotifier } from "../Notifier"
import axios from "../../axiosConfig"

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core"
import Title from "../Title"

const useStyles = makeStyles(() => ({
  root: {},
}))

const intialValues = {
  detailID: "",
  code: "",
  name: "",
  gtype: "",
  period: "",
  serialNo: "",
}

const SingleGlobalCard = ({ setLoading, history }) => {
  const classes = useStyles()

  const [values, setValues] = useState(intialValues)
  const [logs, setLogs] = useState([])

  const getLogs = (code) => {
    axios
      .post(`Logs/code/${code}`)
      .then((res) => {
        setLogs(res.data)
      })
      .catch((error) => {
        errorNotifier(`Error happend while getting logs ...(${error.message})`)
      })
  }

  useEffect(() => {
    let state = history.location.state

    if (state) {
      setValues({
        ...values,
        ...state,
      })
    } else {
      errorNotifier("Somethign went wrong please try again.")
    }
    if (state.code) {
      getLogs(state.code)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <AdminPanel>
      <div className={classes.root}>
        <Card>
          <CardHeader
            title="Card Information"
            subheader="Global card information and logs"
          />
          <Divider />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Global Name"
                  name="name"
                  disabled
                  value={values.name}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Global Type"
                  name="gtype"
                  disabled
                  value={values.gtype}
                  variant="outlined"
                />
              </Grid>

              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Period Type"
                  name="period"
                  disabled
                  value={values.period}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Card"
                  name="code"
                  disabled
                  value={values.code}
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
        </Card>
      </div>
      <Grid container spacing={1} style={{ marginTop: "10px" }}>
        <>
          <Grid item xs={12} container justify="space-evenly">
            <Grid item xs={12}>
              <Title>Card Log</Title>
            </Grid>
          </Grid>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell className={classes.rowHeader} align="left">
                  Date
                </TableCell>
                <TableCell className={classes.rowHeader} align="left">
                  SerialNo
                </TableCell>
                <TableCell className={classes.rowHeader} align="left">
                  Submit
                </TableCell>
                <TableCell className={classes.rowHeader} align="center">
                  Result
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {logs.map((row, index) => (
                <TableRow key={index}>
                  <TableCell align="left">
                    {new Date(`${row.date}Z`)
                      .toString()
                      .replace("GMT+0300 (Arabian Standard Time)", "")}
                  </TableCell>
                  <TableCell align="left">{row.sn}</TableCell>
                  <TableCell align="left">{row.submit}</TableCell>
                  <TableCell align="center">{row.result}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </>
      </Grid>
    </AdminPanel>
  )
}

export default withRouter(SingleGlobalCard)
