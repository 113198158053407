import React from "react"
import { Container } from "@material-ui/core"
import AdminPanel from "../AdminPanel"
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
} from "@material-ui/core"
import AddOutlined from "@material-ui/icons/AddOutlined"
import SettingsOutlined from "@material-ui/icons/UpdateOutlined"
import ViewerIcon from "@material-ui/icons/ListAltOutlined"
import { withRouter } from "react-router-dom"

const GlobalPackages = ({ history }) => {
  return (
    <AdminPanel>
      <Container maxWidth={false}>
        <Card>
          <CardHeader
            title="Global Packages"
            subheader="List of global package actions"
          />
          <Divider />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item md={4} xs={12}>
                <Card>
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="center"
                    alignItems="center"
                    alignContent="center"
                    p={1}
                    m={1}
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      history.push("global/setprice")
                    }}
                  >
                    <Box p={1}>
                      <SettingsOutlined
                        style={{ fontSize: "25px" }}
                      ></SettingsOutlined>
                    </Box>
                    <Box p={2} style={{ fontSize: "20px" }}>
                      Set Package Price
                    </Box>
                  </Box>
                </Card>
              </Grid>
              <Grid item md={4} xs={12}>
                <Card>
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="center"
                    alignItems="center"
                    alignContent="center"
                    p={1}
                    m={1}
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      history.push("global/viewer")
                    }}
                  >
                    <Box p={1}>
                      <ViewerIcon style={{ fontSize: "25px" }}></ViewerIcon>
                    </Box>
                    <Box p={2} style={{ fontSize: "20px" }}>
                      View Packages
                    </Box>
                  </Box>
                </Card>
              </Grid>
              <Grid item md={4} xs={12}>
                <Card>
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="center"
                    alignItems="center"
                    alignContent="center"
                    p={1}
                    m={1}
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      history.push("global/add")
                    }}
                  >
                    <Box p={1}>
                      <AddOutlined style={{ fontSize: "25px" }}></AddOutlined>
                    </Box>
                    <Box p={2} style={{ fontSize: "20px" }}>
                      Add Package Card
                    </Box>
                  </Box>
                </Card>
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
        </Card>
      </Container>
    </AdminPanel>
  )
}

export default withRouter(GlobalPackages)
