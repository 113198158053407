import React, { useState, useEffect } from "react"
import AdminPanel from "../AdminPanel"
import { Card, CardHeader, Divider, Grid, makeStyles } from "@material-ui/core"
import { withRouter } from "react-router-dom"
import { errorNotifier } from "../Notifier"
import axios from "../../axiosConfig"

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core"
import Title from "../Title"

const useStyles = makeStyles(() => ({
  root: {},
}))

const intialValues = {
  userID: "",
}

const SingleGlobalCard = ({ history }) => {
  const classes = useStyles()

  // eslint-disable-next-line
  const [values, setValues] = useState(intialValues)
  const [logs, setLogs] = useState([])

  const getLogs = (userID) => {
    axios
      .post(`Logs/userID/${userID}`)
      .then((res) => {
        setLogs(res.data)
      })
      .catch((error) => {
        errorNotifier(`Error happend while getting logs ...(${error.message})`)
      })
  }

  useEffect(() => {
    let { state } = history.location

    if (state) {
      setValues({
        userID: state,
      })
    } else {
      errorNotifier("Somethign went wrong please try again.")
    }
    if (state) {
      getLogs(state)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <AdminPanel>
      <div className={classes.root}>
        <Card>
          <CardHeader
            title="Card Information"
            subheader="Global card information and logs"
          />
          <Divider />
          <Grid container spacing={1} style={{ marginTop: "10px" }}>
            <>
              <Grid item xs={12} container justify="space-evenly">
                <Grid item xs={12} style={{ paddingLeft: "10px" }}>
                  <Title>User's Card Logs</Title>
                </Grid>
              </Grid>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.rowHeader} align="left">
                      Date
                    </TableCell>
                    <TableCell className={classes.rowHeader} align="left">
                      Card
                    </TableCell>
                    <TableCell className={classes.rowHeader} align="left">
                      SerialNo
                    </TableCell>
                    <TableCell className={classes.rowHeader} align="left">
                      Submit
                    </TableCell>
                    <TableCell className={classes.rowHeader} align="center">
                      Result
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {logs.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell align="left">
                        {new Date(`${row.date}Z`)
                          .toString()
                          .replace("GMT+0300 (Arabian Standard Time)", "")}
                      </TableCell>
                      <TableCell align="left">{row.card}</TableCell>
                      <TableCell align="left">{row.sn}</TableCell>
                      <TableCell align="left">{row.submit}</TableCell>
                      <TableCell align="center">{row.result}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </>
          </Grid>
        </Card>
      </div>
    </AdminPanel>
  )
}

export default withRouter(SingleGlobalCard)
