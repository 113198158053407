import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { Container, Grid } from "@material-ui/core"
import Profile from "./Profile"
import ProfileDetails from "./ProfileDetails"
import AdminPanel from "../AdminPanel"
import { errorNotifier } from "../Notifier"
import axios from "../../axiosConfig"

const Account = ({ admin, setLoading }) => {
  const { userID } = admin
  const [currentAdmin, setCurrentAdmin] = useState({})

  const getCurrentAdmin = (e) => {
    axios
      .get(`users/${userID}`)
      .then(({ data }) => {
        setCurrentAdmin(data)
      })
      .catch((error) => {
        errorNotifier(
          `Error happend while get current admin information ...(${error.message})`
        )
      })
  }

  useEffect(() => {
    getCurrentAdmin()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <AdminPanel>
      <Container maxWidth="lg">
        <Grid container spacing={3}>
          <Grid item lg={4} md={6} xs={12}>
            <Profile admin={currentAdmin} />
          </Grid>
          <Grid item lg={8} md={6} xs={12}>
            <ProfileDetails admin={currentAdmin} setLoading={setLoading} />
          </Grid>
        </Grid>
      </Container>
    </AdminPanel>
  )
}

const mapStateToProps = (state) => {
  return { admin: state.admin }
}

export default connect(mapStateToProps)(Account)
