import React, { useState, useEffect } from "react"
import AdminPanel from "../AdminPanel"
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  makeStyles,
} from "@material-ui/core"
import axios from "../../axiosConfig"
import { errorNotifier, successNotifier } from "../Notifier"

const useStyles = makeStyles(() => ({
  root: {},
}))

const LocalSetPrice = ({ setLoading }) => {
  const classes = useStyles()
  const [localTypes, setLocalTypes] = useState([])
  const [periodTypes, setPeriodTypes] = useState([])

  const [values, setValues] = useState({
    detailID: "",
    localID: "",
    periodID: "",
    price: "",
    discount: "",
  })

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    })
  }

  const getOldPrice = (intialLocalID, intialPeriodID) => {
    setLoading(true)
    axios
      .post("Codetypes/LocalDetail", {
        localID: values.localID ? Number(values.localID) : intialLocalID,
        periodID: values.periodID ? Number(values.periodID) : intialPeriodID,
      })
      .then((res) => {
        setValues({
          localID: values.localID || intialLocalID,
          periodID: values.periodID || intialPeriodID,
          detailID: res.data.detailID,
          price: res.data.price,
          discount: res.data.discount,
        })
        setLoading(false)
      })
      .catch((error) => {
        errorNotifier(
          `Somethign went wrong, cannot set local price ...(${error.message})`
        )

        setLoading(false)
      })
  }

  const setPrice = () => {
    if (values.detailID === "") {
      errorNotifier("Something went wrong, refresh the page")
      return
    }
    if (values.localID === "") {
      errorNotifier("Please select local type")
      return
    }
    if (values.periodID === "") {
      errorNotifier("Please select period type")
      return
    }

    if (values.price === "" || isNaN(values.price)) {
      errorNotifier("Please input appropriate price")
      return
    }
    if (
      values.discount !== "" &&
      Number(values.discount) > Number(values.price)
    ) {
      errorNotifier("Discount cannot be greater than price")
      return
    }

    let data = {
      detailID: values["detailID"],
      localID: Number(values["localID"]),
      periodID: Number(values["periodID"]),
      price: Number(values["price"]),
      discount: Number(values["discount"]) > 0 ? Number(values["discount"]) : 0,
    }

    setLoading(true)
    axios
      .put("codetypes/Detail", data)
      .then((res) => {
        successNotifier("Local package price setted successfully")
        setLoading(false)
      })
      .catch((error) => {
        errorNotifier(
          `Somethign went wrong, cannot set local price ...(${error.message})`
        )
        setLoading(false)
      })
  }

  const fetchLocaPeriodTypes = () => {
    setLoading(true)
    Promise.all([axios.get(`codetypes/Local`), axios.get(`codetypes/Period`)])
      .then(([localRes, periodRes]) => {
        setLocalTypes(localRes.data)
        setPeriodTypes(periodRes.data)
        setValues({
          localID: localRes.data[0]["localID"],
          periodID: periodRes.data[0]["periodID"],
          price: "",
          detailID: "",
        })
        getOldPrice(localRes.data[0]["localID"], periodRes.data[0]["periodID"])
        setLoading(false)
      })
      .catch((error) => {
        errorNotifier(
          `Somethign went wrong, please try again ...(${error.message})`
        )
        setLoading(false)
      })
  }

  useEffect(
    () => {
      if (values.localID && values.periodID) {
        getOldPrice()
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [values.localID, values.periodID]
  )

  useEffect(() => {
    fetchLocaPeriodTypes()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <AdminPanel>
      <div className={classes.root}>
        <Card>
          <CardHeader
            title="Set Local Price"
            subheader="Setting price for local packages"
          />
          <Divider />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Select Local Type"
                  name="localID"
                  onChange={handleChange}
                  required
                  select
                  SelectProps={{ native: true }}
                  value={values.localID}
                  variant="outlined"
                >
                  {localTypes.map((option) => (
                    <option key={option.localID} value={option.localID}>
                      {option.name}
                    </option>
                  ))}
                </TextField>
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Select Period Type"
                  name="periodID"
                  onChange={handleChange}
                  required
                  select
                  SelectProps={{ native: true }}
                  value={values.periodID}
                  variant="outlined"
                >
                  {periodTypes.map((option) => (
                    <option key={option.periodID} value={option.periodID}>
                      {option.name}
                    </option>
                  ))}
                </TextField>
              </Grid>

              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  helperText="Please specify the price"
                  label="price"
                  name="price"
                  onChange={handleChange}
                  required
                  type="number"
                  value={values.price}
                  variant="outlined"
                  inputProps={{ min: "0", step: "1" }}
                />
              </Grid>

              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  helperText="Please specify the discount"
                  label="discount"
                  name="discount"
                  onChange={handleChange}
                  required
                  type="number"
                  value={values.discount}
                  variant="outlined"
                  inputProps={{ min: "0", step: "1" }}
                />
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <Box display="flex" justifyContent="flex-end" p={2}>
            <Button color="primary" variant="contained" onClick={setPrice}>
              Set Price
            </Button>
          </Box>
        </Card>
      </div>
    </AdminPanel>
  )
}

export default LocalSetPrice
