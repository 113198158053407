import React, { useState } from "react"
import Avatar from "@material-ui/core/Avatar"
import Button from "@material-ui/core/Button"
import CssBaseline from "@material-ui/core/CssBaseline"
import TextField from "@material-ui/core/TextField"
import Paper from "@material-ui/core/Paper"
import Grid from "@material-ui/core/Grid"
import LockOutlinedIcon from "@material-ui/icons/LockOutlined"
import Typography from "@material-ui/core/Typography"
import { makeStyles } from "@material-ui/core/styles"
import { withRouter } from "react-router-dom"

import classNames from "classnames"

import { connect } from "react-redux"
import { loginAdmin, logoutAdmin } from "../../actions/adminActions"
import { successNotifier, errorNotifier } from "../Notifier"
import axios from "../../axiosConfig"
import styles from "./SigninStyle"

const useStyles = makeStyles(styles)

function Signin(props) {
  const classes = useStyles()
  const { login, history } = props

  const [adminname, setAdminname] = useState("")
  const [password, setPassword] = useState("")

  const goToPath = props.location.state?.from.pathname ?? "/"

  const SigninAdmin = (e) => {
    e.preventDefault()

    if (adminname === "" || password === "") {
      errorNotifier("Please fill in admin name and password")
      return
    }

    axios
      .post(`AdminLogin`, {
        username: adminname,
        password: password,
      })
      .then((res) => {
        login(res.data)
        successNotifier("Admin logged in successfully")
        history.push(goToPath)
      })
      .catch((error) => {
        errorNotifier(
          `Error happend while loggin admin in ...(${error.message})`
        )
      })
  }

  return (
    <div>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <Grid container component="main" className={classes.root}>
          <CssBaseline />
          <Grid item xs={false} sm={4} md={7} className={classes.image} />
          <Grid
            item
            xs={12}
            sm={8}
            md={5}
            component={Paper}
            elevation={0}
            square
          >
            <div className={classes.paper}>
              <Avatar className={classes.avatar}>
                <LockOutlinedIcon />
              </Avatar>
              <Typography component="h1" variant="h5">
                Sign in
              </Typography>
              <div className={classes.form}>
                <TextField
                  onChange={(e) => {
                    let extractedAdminname = e.target.value
                    setAdminname(extractedAdminname)
                  }}
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="adminname"
                  label="Admin Name"
                  name="adminname"
                  autoComplete="adminname"
                  autoFocus
                />
                <TextField
                  onChange={(e) => {
                    let extractedPassword = e.target.value

                    setPassword(extractedPassword)
                  }}
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                />

                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  onClick={SigninAdmin}
                >
                  Sign In
                </Button>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return { admin: state.admin }
}

const mapDispatchToProps = (dispatch) => {
  return {
    login: (admin) => {
      dispatch(loginAdmin(admin))
    },
    logout: () => {
      dispatch(logoutAdmin())
    },
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Signin))
