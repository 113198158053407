import React from "react"
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import MenuIcon from "@material-ui/icons/Menu"
import HomeIcon from "@material-ui/icons/HomeOutlined"
import ExitToAppIcon from "@material-ui/icons/ExitToAppOutlined"
import { makeStyles } from "@material-ui/core/styles"
import clsx from "clsx"
import IconButton from "@material-ui/core/IconButton"
import Typography from "@material-ui/core/Typography"
import { Box } from "@material-ui/core"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import { logoutAdmin } from "../actions/adminActions"
import axios from "../axiosConfig"
import { successNotifier } from "./Notifier"
import styles from "./AdminPanelStyle"

const useStyles = makeStyles(styles)

function MyAppBar(props) {
  const { open, handleDrawerOpen } = props
  const { history, admin, logout } = props

  const exitToApp = () => {
    const { token } = admin

    axios.post(`Logout`, {
      token: token,
    })
    successNotifier("Admin logged out successfully")
    logout()
  }

  const classes = useStyles()
  return (
    <AppBar
      position="fixed"
      className={clsx(classes.appBar, {
        [classes.appBarShift]: open,
      })}
    >
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          edge="start"
          className={clsx(classes.menuButton, {
            [classes.hide]: open,
          })}
        >
          <MenuIcon />
        </IconButton>
        <Typography variant="h6" noWrap>
          Receivers System - Admin Panel
        </Typography>
        <Box
          display="flex"
          justifyContent="flex-end"
          alignItems="flex"
          flexGrow={1}
        >
          <IconButton
            color="inherit"
            onClick={() => {
              history.push("/")
            }}
          >
            <HomeIcon />
          </IconButton>
          <IconButton color="inherit" onClick={exitToApp}>
            <ExitToAppIcon />
          </IconButton>
        </Box>
      </Toolbar>
    </AppBar>
  )
}

const mapStateToProps = (state) => {
  return { admin: state.admin }
}

const mapDispatchToProps = (dispatch) => {
  return {
    logout: (admin) => {
      dispatch(logoutAdmin())
    },
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MyAppBar)
)
