import * as actionTypes from "./actionTypes"

export const loginAdmin = (admin) => {
  let data = admin
  return { type: actionTypes.LOGIN_ADMIN, payload: { ...data } }
}

export const logoutAdmin = () => {
  return { type: actionTypes.LOGOUT_ADMIN, payload: {} }
}
