import React, { useState } from "react"
import clsx from "clsx"
import PropTypes from "prop-types"
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  makeStyles,
  TextField,
  Button,
  Box,
  Typography,
} from "@material-ui/core"
import axios from "../../axiosConfig"
import { errorNotifier, successNotifier } from "../Notifier"
import { checkPassStrength } from "../utils"

const useStyles = makeStyles(() => ({
  root: {},
}))

const ProfileDetails = ({ admin, setLoading, className }) => {
  const classes = useStyles()

  const [values, setValues] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  })

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    })
  }

  const changePassword = () => {
    if (!admin.userID) {
      errorNotifier("Admin information is not available")
      return
    }

    if (
      values.newPassword === "" ||
      values.confirmPassword === "" ||
      values.oldPassword === ""
    ) {
      errorNotifier("Please fill in all password fields")
      return
    }

    if (values.newPassword !== values.confirmPassword) {
      errorNotifier("New password and confirm password dosn't match.")
      return
    }

    if (
      values.newPassword.length !== 0 &&
      values.newPassword.length !== 0 &&
      values.newPassword.length < 8
    ) {
      errorNotifier("Please user strongger password more than 8 alphanumeric")
      return
    }

    setLoading(true)
    axios
      .post(`Users/passchange`, {
        userID: admin.userID,
        oldPassword: values.oldPassword,
        newPassword: values.newPassword,
      })
      .then((res) => {
        successNotifier("Password changed successfully")
        setLoading(false)
      })
      .catch((error) => {
        errorNotifier(
          `Somethign went wrong, please try again ...(${error.message})`
        )
        setLoading(false)
      })
  }

  return (
    <div className={clsx(classes.root, className)}>
      <Card>
        <CardHeader subheader="Change admin password" title="Security" />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Old Password"
                name="oldPassword"
                onChange={handleChange}
                required
                value={values.oldPassword}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="New Password"
                name="newPassword"
                onChange={handleChange}
                required
                value={values.newPassword}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Confirm Password"
                name="confirmPassword"
                onChange={handleChange}
                required
                value={values.confirmPassword}
                variant="outlined"
              />
            </Grid>
            <div
              style={{ width: "96%", marginLeft: "auto", marginRight: "auto" }}
            >
              {values.newPassword && (
                <Typography
                  style={{
                    color:
                      checkPassStrength(values.newPassword) === "weak"
                        ? "red"
                        : checkPassStrength(values.newPassword) === "good"
                        ? "green"
                        : "darkgreen",
                  }}
                >
                  {"Your password strength is : "}
                  {checkPassStrength(values.newPassword)}
                </Typography>
              )}
              <Typography
                variant="body2"
                style={{ color: "grey", marginTop: "20px" }}
              >
                tips: for stronger password please use digits, lower, and upper
                case alphabets and non-word
              </Typography>
            </div>
          </Grid>
        </CardContent>
        <Divider />
        <Box display="flex" justifyContent="flex-end" p={1}>
          <Button color="primary" variant="contained" onClick={changePassword}>
            Change Password
          </Button>
        </Box>
      </Card>
    </div>
  )
}

ProfileDetails.propTypes = {
  className: PropTypes.string,
  admin: PropTypes.object,
}

export default ProfileDetails
