import React, { useState, useEffect } from "react"
import AdminPanel from "../AdminPanel"
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  makeStyles,
} from "@material-ui/core"
import axios from "../../axiosConfig"
import { errorNotifier, successNotifier } from "../Notifier"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import { findDuplicate, toFixed2 } from "./../utils"

const useStyles = makeStyles(() => ({
  root: {},
}))

const intialValues = {
  detailID: "",
  globalID: "",
  globalSubID: "",
  periodID: "",
  price: "",
  amount: "",
  cards: {},
}

const AddGlobalCard = ({ setLoading, admin, history }) => {
  const classes = useStyles()
  const [globalTypes, setGlobalTypes] = useState([])
  const [globalSubTypes, setGlobalSubTypes] = useState([])
  const [periodTypes, setPeriodTypes] = useState([])

  const [values, setValues] = useState(intialValues)

  const handleChange = (event) => {
    if (event.target.name === "amount") {
      setValues({
        ...values,
        cards: {},
        [event.target.name]: event.target.value,
      })
    } else {
      setValues({
        ...values,
        [event.target.name]: event.target.value,
      })
    }
  }

  const getGlobalSubIDs = () => {
    setLoading(true)
    axios
      .get(`codetypes/Global/type/${values.globalID}`)
      .then((globalSubRes) => {
        setGlobalSubTypes(globalSubRes.data)
        setValues({
          ...values,
          globalSubID: globalSubRes.data[0]["gtypeID"],
        })

        setLoading(false)
      })
      .catch((error) => {
        errorNotifier(
          `Somethign went wrong, please try again ...(${error.message})`
        )
        setLoading(false)
      })
  }

  const fetchGlobalPeriodTypes = () => {
    setLoading(true)
    Promise.all([
      axios.get(`codetypes/global`),
      axios.get(`codetypes/Global/type/1`),
      axios.get(`codetypes/Period`),
    ])
      .then(([globalRes, globalSubRes, periodRes]) => {
        setGlobalTypes(globalRes.data)
        setGlobalSubTypes(globalSubRes.data)
        setPeriodTypes(periodRes.data)
        setValues({
          ...values,
          globalID: globalRes.data[0]["globalID"],
          globalSubID: globalSubRes.data[0]["gtypeID"],
          periodID: periodRes.data[0]["periodID"],
        })
        setLoading(false)
      })
      .catch((error) => {
        errorNotifier(
          `Somethign went wrong, please try again ...(${error.message})`
        )
        setLoading(false)
      })
  }

  const getOldPrice = (intialGlobalSubID, intialPeriodID) => {
    setLoading(true)
    axios
      .post("Codetypes/GlobalDetail", {
        gtypeID: values.globalSubID
          ? Number(values.globalSubID)
          : intialGlobalSubID,
        periodID: values.periodID ? Number(values.periodID) : intialPeriodID,
      })
      .then((res) => {
        setValues({
          ...values,
          detailID: res.data.detailID,
          price: res.data.price,
        })
        setLoading(false)
      })
      .catch((error) => {
        errorNotifier(
          `Somethign went wrong, cannot set global price ...(${error.message})`
        )
        setLoading(false)
      })
  }

  const addCards = () => {
    let userID = admin.userID

    if (!userID) {
      errorNotifier("No logged admin found please try again...")
      return
    }

    if (Object.keys(values.cards).length === 0) {
      errorNotifier("Please enter at least one card number")
      return
    }

    if (values.detailID === "") {
      errorNotifier("Please select correct package and period")
      return
    }

    if (values.price === "") {
      errorNotifier("Card price is missing, please set card price first")
      return
    }

    let errorLength = false
    Object.keys(values.cards).forEach((key, index) => {
      if (
        !(
          values.cards[key].card.trim().length >= 13 &&
          values.cards[key].card.trim().length <= 15
        )
      ) {
        errorNotifier(
          `Card number length must be between 13 to 15 characters  ${key} is ${
            values.cards[key].card.trim().length
          }  `
        )
        errorLength = true
      }
    })

    if (errorLength) {
      return
    }

    let finalCards = []

    Object.keys(values.cards).forEach((key, index) => {
      if (
        values.cards[key].card.trim().length >= 13 &&
        values.cards[key].card.trim().length <= 15
      ) {
        finalCards.push({
          detailID: values.detailID,
          code: values.cards[key].card.trim(),
          quantity: 1,
          price: values.price,
        })
      }
    })

    let codes = finalCards.map((card) => card.code)
    let duplicatedCodes = findDuplicate(codes)
    if (duplicatedCodes.length > 0) {
      errorNotifier(
        `card: ${duplicatedCodes.join(
          "  card: "
        )} are duplicate, card number must be unique`
      )
      return
    }

    let data = {
      userID: userID,
      date: new Date().toJSON(),
      Transaction: {
        paid: finalCards.length * values.price,
      },
      Codes: finalCards,
    }

    setLoading(true)
    axios
      .post("records", data)
      .then((res) => {
        successNotifier("Local package cards added successfully")
        setLoading(false)
        setValues(intialValues)
        history.goBack()
      })
      .catch((error) => {
        errorNotifier(
          `Somethign went wrong, cannot add local cards ...(${error.message})`
        )
        setLoading(false)
      })
  }

  useEffect(() => {
    if (values.globalSubID && values.periodID) {
      getOldPrice()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.globalSubID, values.periodID])

  useEffect(() => {
    if (values.globalID) {
      getGlobalSubIDs()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.globalID])

  useEffect(() => {
    fetchGlobalPeriodTypes()
    getOldPrice(1, 1)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleCardChange = (event) => {
    let cards = { ...values.cards }
    cards[event.target.name] = {
      card: event.target.value,
    }

    setValues({
      ...values,
      cards,
    })
  }

  let numberOfGeneratedFields = 0
  if (values.amount !== "") {
    numberOfGeneratedFields = Math.round(Number(values.amount))
  }

  let generatedCards = []
  if (numberOfGeneratedFields > 0) {
    for (let i = 1; i <= numberOfGeneratedFields; i++) {
      generatedCards.push({
        index: i,
      })
    }
  }

  return (
    <AdminPanel>
      <div className={classes.root}>
        <Card>
          <CardHeader
            title="Add Global Card"
            subheader="Adding cards for global packages"
          />
          <Divider />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Select Global Type"
                  name="globalID"
                  onChange={handleChange}
                  required
                  select
                  SelectProps={{ native: true }}
                  value={values.globalID}
                  variant="outlined"
                >
                  {globalTypes.map((option) => (
                    <option key={option.globalID} value={option.globalID}>
                      {option.name}
                    </option>
                  ))}
                </TextField>
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Select Global SubType"
                  name="globalSubID"
                  onChange={handleChange}
                  required
                  select
                  SelectProps={{ native: true }}
                  value={values.globalSubID}
                  variant="outlined"
                >
                  {globalSubTypes.map((option) => (
                    <option key={option.gtypeID} value={option.gtypeID}>
                      {option.name}
                    </option>
                  ))}
                </TextField>
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Select Period Type"
                  name="periodID"
                  onChange={handleChange}
                  required
                  select
                  SelectProps={{ native: true }}
                  value={values.periodID}
                  variant="outlined"
                >
                  {periodTypes.map((option) => (
                    <option key={option.periodID} value={option.periodID}>
                      {option.name}
                    </option>
                  ))}
                </TextField>
              </Grid>

              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="price"
                  name="price"
                  disabled
                  value={`${toFixed2(values.price)} $`}
                  variant="outlined"
                  inputProps={{ min: "0", step: "1" }}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  helperText="Please specify the card numbers"
                  label="amount"
                  name="amount"
                  onChange={handleChange}
                  required
                  type="number"
                  value={values.amount}
                  variant="outlined"
                  inputProps={{ min: "0", step: "1" }}
                />
              </Grid>
              {generatedCards.map((item, index) => (
                <Grid item md={6} xs={12} key={index}>
                  <TextField
                    fullWidth
                    label={`Card Number ${item.index}`}
                    name={`card_${item.index}`}
                    onChange={handleCardChange}
                    required
                    value={
                      Object.keys(values.cards).length > 0 &&
                      values.cards[`card_${item.index}`] !== undefined
                        ? values.cards[`card_${item.index}`]["card"]
                        : ""
                    }
                    variant="outlined"
                  />
                </Grid>
              ))}
            </Grid>
          </CardContent>
          <Divider />
          <Box display="flex" justifyContent="flex-end" p={2}>
            <Button color="primary" variant="contained" onClick={addCards}>
              Add Cards
            </Button>
          </Box>
        </Card>
      </div>
    </AdminPanel>
  )
}

const mapStateToProps = (state) => {
  return { admin: state.admin }
}

export default withRouter(connect(mapStateToProps)(AddGlobalCard))
