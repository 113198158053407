import React from "react"
import clsx from "clsx"
import Drawer from "@material-ui/core/Drawer"
import List from "@material-ui/core/List"
import Divider from "@material-ui/core/Divider"
import IconButton from "@material-ui/core/IconButton"
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import DashboardIcon from "@material-ui/icons/DashboardOutlined"
import AdminIcon from "@material-ui/icons/AccountCircleOutlined"
import SearchOutlined from "@material-ui/icons/SearchOutlined"
import CardGiftcardOutlined from "@material-ui/icons/CardGiftcardOutlined"
import HistoryOutlined from "@material-ui/icons/HistoryOutlined"
import MoneyOutlined from "@material-ui/icons/MoneyOutlined"
import ListAltOutlined from "@material-ui/icons/ListAltOutlined"
import { Box } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { withRouter } from "react-router-dom"
import Logo from "./Logo"
import styles from "./AdminPanelStyle"

const useStyles = makeStyles(styles)

function MyDrawer(props) {
  const classes = useStyles()
  const { open, handleDrawerClose, history } = props

  return (
    <Drawer
      variant="permanent"
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        }),
      }}
    >
      <div className={classes.toolbar}>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexGrow={1}
        >
          <Logo width="46px" height="46px"></Logo>
        </Box>
        <IconButton onClick={handleDrawerClose}>
          <ChevronLeftIcon />
        </IconButton>
      </div>
      <Divider />
      <List>
        <ListItem
          button
          onClick={() => {
            history.push("/")
          }}
        >
          <ListItemIcon>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText primary="Dashboard" />
        </ListItem>
        <ListItem
          button
          onClick={() => {
            history.push("/local")
          }}
        >
          <ListItemIcon>
            <CardGiftcardOutlined />
          </ListItemIcon>
          <ListItemText primary="Local Packages" />
        </ListItem>
        <ListItem
          button
          onClick={() => {
            history.push("/global")
          }}
        >
          <ListItemIcon>
            <CardGiftcardOutlined />
          </ListItemIcon>
          <ListItemText primary="Global Packages" />
        </ListItem>

        <ListItem
          button
          onClick={() => {
            history.push("/users")
          }}
        >
          <ListItemIcon>
            <SearchOutlined />
          </ListItemIcon>
          <ListItemText primary="Search User" />
        </ListItem>

        <ListItem
          button
          onClick={() => {
            history.push("/code_history")
          }}
        >
          <ListItemIcon>
            <HistoryOutlined />
          </ListItemIcon>
          <ListItemText primary="Code History" />
        </ListItem>

        <ListItem
          button
          onClick={() => {
            history.push("/profits")
          }}
        >
          <ListItemIcon>
            <MoneyOutlined />
          </ListItemIcon>
          <ListItemText primary="Sold Detail" />
        </ListItem>

        <ListItem
          button
          onClick={() => {
            history.push("/users_report")
          }}
        >
          <ListItemIcon>
            <ListAltOutlined />
          </ListItemIcon>
          <ListItemText primary="Users History" />
        </ListItem>

        <ListItem
          button
          onClick={() => {
            history.push("/account")
          }}
        >
          <ListItemIcon>
            <AdminIcon />
          </ListItemIcon>
          <ListItemText primary="Account" />
        </ListItem>
      </List>
    </Drawer>
  )
}

export default withRouter(MyDrawer)
