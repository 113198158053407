// import all actionas actionTypes  -single source of truth
import * as actionTypes from "../actions/actionTypes"

const admin = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.LOGIN_ADMIN: //  adding information
      return { ...state, ...action.payload }
    case actionTypes.LOGOUT_ADMIN: //  adding information
      return { ...action.payload }

    default:
      // default state of the reducer
      return state
  }
}

export default admin
