import React, { useState, useEffect } from "react"
import AdminPanel from "../AdminPanel"
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  makeStyles,
} from "@material-ui/core"
import axios from "../../axiosConfig"
import { errorNotifier, successNotifier } from "../Notifier"

const useStyles = makeStyles(() => ({
  root: {},
}))

const GlobalSetPrice = ({ setLoading }) => {
  const classes = useStyles()
  const [globalTypes, setGlobalTypes] = useState([])
  const [globalSubTypes, setGlobalSubTypes] = useState([])
  const [periodTypes, setPeriodTypes] = useState([])

  const [values, setValues] = useState({
    detailID: "",
    globalID: "",
    globalSubID: "",
    periodID: "",
    price: "",
    discount: "",
  })

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    })
  }

  const getGlobalSubIDs = () => {
    setLoading(true)
    axios
      .get(`codetypes/Global/type/${values.globalID}`)
      .then((globalSubRes) => {
        setGlobalSubTypes(globalSubRes.data)
        setValues({
          ...values,
          globalSubID: globalSubRes.data[0]["gtypeID"],
        })

        setLoading(false)
      })
      .catch((error) => {
        errorNotifier(
          `Somethign went wrong, please try again ...(${error.message})`
        )
        setLoading(false)
      })
  }

  const fetchGlobalPeriodTypes = () => {
    setLoading(true)
    Promise.all([
      axios.get(`codetypes/global`),
      axios.get(`codetypes/Global/type/1`),
      axios.get(`codetypes/Period`),
    ])
      .then(([globalRes, globalSubRes, periodRes]) => {
        setGlobalTypes(globalRes.data)
        setGlobalSubTypes(globalSubRes.data)
        setPeriodTypes(periodRes.data)
        setValues({
          ...values,
          globalID: globalRes.data[0]["globalID"],
          globalSubID: globalSubRes.data[0]["gtypeID"],
          periodID: periodRes.data[0]["periodID"],
        })
        setLoading(false)
      })
      .catch((error) => {
        errorNotifier(
          `Somethign went wrong, please try again ...(${error.message})`
        )
        setLoading(false)
      })
  }

  const getOldPrice = (intialGlobalSubID, intialPeriodID) => {
    setLoading(true)
    axios
      .post("Codetypes/GlobalDetail", {
        gtypeID:
          values.globalSubID !== ""
            ? Number(values.globalSubID)
            : intialGlobalSubID,
        periodID:
          values.periodID !== "" ? Number(values.periodID) : intialPeriodID,
      })
      .then((res) => {
        setValues({
          ...values,
          detailID: res.data.detailID,
          price: res.data.price,
          discount: res.data.discount,
        })
        setLoading(false)
      })
      .catch((error) => {
        errorNotifier(
          `Somethign went wrong, cannot set global price ...(${error.message})`
        )
        setLoading(false)
      })
  }

  const setPrice = () => {
    if (values.detailID === "") {
      errorNotifier("Something went wrong, refresh the page")
      return
    }
    if (values.globalID === "") {
      errorNotifier("Please select global type")
      return
    }
    if (values.globalSubID === "") {
      errorNotifier("Please select global sub type")
      return
    }
    if (values.periodID === "") {
      errorNotifier("Please select period type")
      return
    }

    if (values.price === "" || isNaN(values.price)) {
      errorNotifier("Please input appropriate price")
      return
    }

    if (
      values.discount !== "" &&
      Number(values.discount) > Number(values.price)
    ) {
      errorNotifier("Discount cannot be greater than price")
      return
    }

    let data = {
      detailID: values["detailID"],
      gtypeID: Number(values["globalSubID"]),
      periodID: Number(values["periodID"]),
      price: Number(values["price"]),
      discount: Number(values["discount"]) > 0 ? Number(values["discount"]) : 0,
    }

    setLoading(true)
    axios
      .put("codetypes/Detail", data)
      .then((res) => {
        successNotifier("Global package price setted successfully")
        setLoading(false)
      })
      .catch((error) => {
        errorNotifier(
          `Somethign went wrong, cannot set global price ...(${error.message})`
        )
        setLoading(false)
      })
  }

  useEffect(() => {
    if (values.globalSubID && values.periodID) {
      getOldPrice()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.globalSubID, values.periodID])

  useEffect(() => {
    if (values.globalID) {
      getGlobalSubIDs()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.globalID])

  useEffect(() => {
    fetchGlobalPeriodTypes()
    getOldPrice(1, 1)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <AdminPanel>
      <div className={classes.root}>
        <Card>
          <CardHeader
            title="Set Global Price"
            subheader="Setting price for global packages"
          />
          <Divider />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Select Global Type"
                  name="globalID"
                  onChange={handleChange}
                  required
                  select
                  SelectProps={{ native: true }}
                  value={values.globalID}
                  variant="outlined"
                >
                  {globalTypes.map((option) => (
                    <option key={option.globalID} value={option.globalID}>
                      {option.name}
                    </option>
                  ))}
                </TextField>
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Select Global SubType"
                  name="globalSubID"
                  onChange={handleChange}
                  required
                  select
                  SelectProps={{ native: true }}
                  value={values.globalSubID}
                  variant="outlined"
                >
                  {globalSubTypes.map((option) => (
                    <option key={option.gtypeID} value={option.gtypeID}>
                      {option.name}
                    </option>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Select Period Type"
                  name="periodID"
                  onChange={handleChange}
                  required
                  select
                  SelectProps={{ native: true }}
                  value={values.periodID}
                  variant="outlined"
                >
                  {periodTypes.map((option) => (
                    <option key={option.periodID} value={option.periodID}>
                      {option.name}
                    </option>
                  ))}
                </TextField>
              </Grid>

              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  helperText="Please specify the price"
                  label="price"
                  name="price"
                  onChange={handleChange}
                  required
                  type="number"
                  value={values.price}
                  variant="outlined"
                  inputProps={{ min: "0", step: "1" }}
                />
              </Grid>

              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  helperText="Please specify the discount"
                  label="discount"
                  name="discount"
                  onChange={handleChange}
                  required
                  type="number"
                  value={values.discount}
                  variant="outlined"
                  inputProps={{ min: "0", step: "1" }}
                />
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <Box display="flex" justifyContent="flex-end" p={2}>
            <Button color="primary" variant="contained" onClick={setPrice}>
              Set Price
            </Button>
          </Box>
        </Card>
      </div>
    </AdminPanel>
  )
}

export default GlobalSetPrice
