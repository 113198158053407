import React, { useState, useEffect } from "react"

import { Container } from "@material-ui/core"
import AdminPanel from "../AdminPanel"
import clsx from "clsx"
import PropTypes from "prop-types"
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  makeStyles,
} from "@material-ui/core"
import { errorNotifier, warnNotifier } from "../Notifier"
import axios from "../../axiosConfig"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import { withRouter } from "react-router-dom"
import Title from "../Title"
import TransferIcon from "@material-ui/icons/AttachMoney"
import AddIcon from "@material-ui/icons/Add"
import EditIcon from "@material-ui/icons/EditOutlined"
import HistoryOutlined from "@material-ui/icons/HistoryOutlined"

const useStyles = makeStyles(() => ({
  root: {},
  addUser: { textAlign: "right", padding: "20px" },
}))

const intialValues = {
  usergroupID: 2,
  term: "",
}

const Users = ({ className, history, setLoading }) => {
  const classes = useStyles()
  const [values, setValues] = useState(intialValues)
  const [users, setUsers] = useState([])

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    })
  }

  const searchUser = (e, searchTerm) => {
    let keyword = searchTerm === undefined ? values.term : searchTerm

    if (!keyword) {
      errorNotifier("Please fill in term field")
      return
    }

    setLoading(true)

    axios
      .post("users/search", {
        keyword: keyword,
      })
      .then((res) => {
        if (res.data.length === 0) {
          warnNotifier("No user found with this term")
        } else {
          setUsers(res.data)
        }
        setLoading(false)
      })
      .catch((error) => {
        errorNotifier(
          `Something went wrong while searching for users...(${error.message})`
        )
        setLoading(false)
      })
  }

  useEffect(() => {
    let tempTerm = history.location.state?.term

    if (tempTerm) {
      setValues({
        ...values,
        term: tempTerm,
      })
      searchUser(null, tempTerm)
    }

    // eslint-disable-next-line
  }, [])

  return (
    <AdminPanel>
      <Container maxWidth={false}>
        <div className={clsx(classes.root, className)}>
          <Card>
            <Grid container justify="space-evenly">
              <Grid item xs={12} className={classes.addUser}>
                <Button
                  onClick={() => {
                    history.push({
                      pathname: `/users/add`,
                      state: {
                        users: users,
                        term: values.term,
                      },
                    })
                  }}
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  startIcon={<AddIcon />}
                >
                  Add User
                </Button>
              </Grid>
            </Grid>
            <CardHeader
              subheader="Please fill in term field"
              title="Search User"
            />

            <Divider />
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    helperText="Please specify the search name"
                    label="Term"
                    name="term"
                    onChange={handleChange}
                    required
                    value={values.term}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            </CardContent>
            <Divider />

            <Box
              display="flex"
              justifyContent="flex-end"
              p={2}
              onClick={searchUser}
            >
              <Button color="primary" variant="contained">
                Search User
              </Button>
            </Box>
          </Card>
        </div>
        <Box height="30px"></Box>
        <Grid container spacing={3}>
          <>
            <Grid item xs={12} container justify="space-evenly">
              <Grid item xs={12} className={classes.alignLeft}>
                <Title>Result</Title>
              </Grid>
            </Grid>

            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell className={classes.rowHeader}>Name</TableCell>
                  <TableCell className={classes.rowHeader}>User Name</TableCell>
                  <TableCell className={classes.rowHeader}>Address</TableCell>
                  <TableCell className={classes.rowHeader}>Phone</TableCell>
                  <TableCell className={classes.rowHeader}>Email</TableCell>
                  <TableCell className={classes.rowHeader}>Discount</TableCell>
                  <TableCell className={classes.rowHeader}>Block</TableCell>
                  <TableCell className={classes.rowHeader} align="center">
                    Edit
                  </TableCell>
                  <TableCell className={classes.rowHeader} align="center">
                    Transfer
                  </TableCell>
                  <TableCell className={classes.rowHeader} align="center">
                    Logs
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {users.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell>{row.name || "..."}</TableCell>
                    <TableCell>{row.userName || "..."}</TableCell>
                    <TableCell>{row.address || "..."}</TableCell>
                    <TableCell>{row.phone || "..."}</TableCell>
                    <TableCell>{row.email || "..."}</TableCell>
                    <TableCell>
                      {row.discount === true ? "Yes" : "No"}
                    </TableCell>
                    <TableCell>{row.block === true ? "Yes" : "No"}</TableCell>
                    <TableCell
                      align="center"
                      onClick={() => {
                        history.push({
                          pathname: `/users/edit`,
                          state: {
                            user: row,
                            users: users,
                            term: values.term,
                          },
                        })
                      }}
                    >
                      <EditIcon className={classes.icon}></EditIcon>
                    </TableCell>

                    <TableCell
                      align="center"
                      onClick={() => {
                        history.push({
                          pathname: `/users/transfer`,
                          state: {
                            user: row,
                            term: values.term,
                          },
                        })
                      }}
                    >
                      <TransferIcon className={classes.icon}></TransferIcon>
                    </TableCell>

                    <TableCell
                      align="center"
                      onClick={() => {
                        history.push({
                          pathname: `/users/logs`,
                          state: row.userID,
                        })
                      }}
                    >
                      <HistoryOutlined
                        className={classes.icon}
                      ></HistoryOutlined>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </>
        </Grid>
      </Container>
    </AdminPanel>
  )
}

Users.propTypes = {
  className: PropTypes.string,
  setLoading: PropTypes.func,
}

export default withRouter(Users)
