import React, { useState, useEffect } from "react"
import { Container } from "@material-ui/core"
import AdminPanel from "../AdminPanel"
import clsx from "clsx"
import { makeStyles } from "@material-ui/core/styles"
import Grid from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper"
import Typography from "@material-ui/core/Typography"
import Box from "@material-ui/core/Box"
import Copyright from "./../Copyright"
import Title from "../Title"
import { connect } from "react-redux"
import Profile from "../account/Profile"
import axios from "../../axiosConfig"
import { errorNotifier } from "./../Notifier"
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core"
import { getLastWeekDate, toFixed2 } from "../utils"

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(1),
    display: "flex",
    overflow: "hidden",
    flexDirection: "column",
    width: "100%",
  },
  fixedHeight: {
    height: 355,
  },
}))

const MyDashboard = ({ admin }) => {
  const classes = useStyles()
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight)

  const [balance, setBalance] = useState(0)
  const [logs, setLogs] = useState([])
  const [currentAdmin, setCurrentAdmin] = useState({})

  const [startdate] = useState(getLastWeekDate())
  const [enddate] = useState(new Date())

  const { userID } = admin

  const getProfits = () => {
    axios
      .post(`Records/benefit`, {
        startdate: startdate,
        enddate: enddate,
      })
      .then((res) => {
        if (res.data?.length === 0) {
          setBalance(0)
        } else {
          let total = 0
          res.data.forEach((item) => {
            total = total + item.total
          })
          setBalance(total)
        }
      })
  }

  const getCurrentAdmin = (e) => {
    axios
      .get(`users/${userID}`)
      .then(({ data }) => {
        setCurrentAdmin(data)
      })
      .catch((error) => {
        errorNotifier(
          `Error happend while get current admin information ...(${error.message})`
        )
      })
  }

  const getLogs = () => {
    axios
      .post(`Records/perdate`, {
        startdate: startdate,
        enddate: enddate,
      })
      .then((res) => {
        if (res.data?.length === 0) {
          setLogs([])
        } else {
          setLogs(res.data.slice(0, 3))
        }
      })
  }

  useEffect(() => {
    getLogs()
    getCurrentAdmin()
    getProfits()
    // eslint-disable-next-line
  }, [])

  return (
    <AdminPanel>
      <Container maxWidth="lg">
        <Grid container spacing={1}>
          <Grid item xs={12} md={8}>
            <Paper>
              <Profile admin={currentAdmin} />
            </Paper>
          </Grid>
          <Grid item xs={12} md={4}>
            <Paper className={fixedHeightPaper}>
              <div
                style={{
                  marginBottom: "10px",
                  textAlign: "center",
                  paddingTop: "50px",
                }}
              >
                <Title>Last Weeks Sold</Title>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "50%",
                }}
              >
                <Typography variant="h4">
                  {balance ? `${toFixed2(balance)} $` : ""}
                </Typography>
              </div>
            </Paper>
          </Grid>

          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Paper className={classes.paper} style={{ minHeight: "240px" }}>
                {logs.length > 0 && (
                  <>
                    <Grid item xs={12} container justify="space-evenly">
                      <Grid item xs={12}>
                        <Title>Recent Codes</Title>
                      </Grid>
                    </Grid>

                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell
                            className={classes.rowHeader}
                            align="center"
                          >
                            Date
                          </TableCell>
                          <TableCell
                            className={classes.rowHeader}
                            align="center"
                          >
                            Name
                          </TableCell>
                          <TableCell
                            className={classes.rowHeader}
                            align="center"
                          >
                            Type
                          </TableCell>
                          <TableCell
                            className={classes.rowHeader}
                            align="center"
                          >
                            Period
                          </TableCell>
                          <TableCell
                            className={classes.rowHeader}
                            align="center"
                          >
                            Code
                          </TableCell>
                          <TableCell
                            className={classes.rowHeader}
                            align="center"
                          >
                            Record
                          </TableCell>
                          <TableCell
                            className={classes.rowHeader}
                            align="center"
                          >
                            Charged
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {logs.map((row, index) => (
                          <TableRow key={index}>
                            <TableCell align="center">
                              {new Date(`${row.date}Z`)
                                .toString()
                                .replace(
                                  "GMT+0300 (Arabian Standard Time)",
                                  ""
                                )}
                            </TableCell>
                            <TableCell align="center">{row.name}</TableCell>
                            <TableCell align="center">
                              {row.gtype || "..."}
                            </TableCell>
                            <TableCell align="center">{row.period}</TableCell>
                            <TableCell align="center">{row.code}</TableCell>
                            <TableCell align="center">{row.type}</TableCell>
                            <TableCell align="center">
                              {row.carged === true ? "Yes" : "No"}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </>
                )}
              </Paper>
            </Grid>
          </Grid>
        </Grid>
        <Box pt={4}>
          <Copyright color="black" />
        </Box>
      </Container>
    </AdminPanel>
  )
}

const mapStateToProps = (state) => {
  return { admin: state.admin }
}

export default connect(mapStateToProps)(MyDashboard)
